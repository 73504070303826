










































































































































































































































































































































import {
  computed,
  defineComponent,
  provide,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";
import { getConsumptionDataList } from "@/api/energy-data/consumption_data";
import {
  deleteConsumptionSiteData,
  getConsumptionSiteData
} from "@/api/energy-assets/consumption_site";
import { dynamicSort, seperateThousand } from "@/utils";
import { formattedAddress } from "@/helpers/formats";
import { rootInstance } from "@/helpers/composition";

import { Notification } from "element-ui";
import { cloneDeep } from "lodash";

import { IConsumptionData } from "@/lib/types/energy-data/consumption_data";
import { IConsumptionSiteDetails } from "@/lib/types/energy-assets/consumption_site";
import { OrganizationTypes } from "@/lib/types/base";

import AnimatedNumber from "animated-number-vue";

export default defineComponent({
  name: "ConsumptionSite",
  components: {
    AggregatedChart: () => import("@/components/charts/AggregatedChart.vue"),
    ApexChart: () => import("@/components/charts/ApexChart.vue"),
    AggregatedDatalist: () =>
      import("@/components/datalists/AggregatedDatalist.vue"),
    PieChart: () => import("@/components/charts/PieChart.vue"),
    ConsumptionMatchingList: () =>
      import("@/components/consumption-site/ConsumptionMatchingList.vue"),
    ConsumptionSiteDetails: () =>
      import("@/components/consumption-site/ConsumptionSiteDetails.vue"),
    ConsumptionSiteCrudForm: () =>
      import("@/components/forms/ConsumptionSiteCrudForm.vue"),
    ConsumptionDataUploadForm: () =>
      import("@/components/forms/ConsumptionDataUploadForm.vue"),
    GetViaEic: () => import("@/components/forms/GetViaEic.vue"),
    AnimatedNumber,
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (
        [OrganizationTypes.RETAILER, OrganizationTypes.CONSUMER].includes(
          vm.$store.state.user.details.organization_type
        )
      ) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    });
  },
  setup() {
    const { root } = rootInstance();

    const roles = computed(() => root.$store.getters["user/roles"]);

    const state = reactive({
      details: { consumer: {} } as IConsumptionSiteDetails,
      detailsInit: {} as IConsumptionSiteDetails,
      detailsLoaded: false,
      consumptionData: {} as Partial<IConsumptionData>,
      consumptionDataLoaded: false,
      datalistVisible: false,
      editModalVisible: false,
      uploadModalVisible: false,
      activeTab: "overview",
      isRouterAlive: true,
      consumptionSiteFormValue: false,
      currentTab: "upload",
      chartName: "Stacked Area Chart",
    });

    const consumptionSiteDataFn = () => {
      state.detailsLoaded = false;
      state.consumptionDataLoaded = false;

      getConsumptionSiteData(root.$route.params.id)
        .then(async res => {
          state.details = cloneDeep(res);
          state.detailsInit = Object.freeze(state.details);
          state.detailsLoaded = true;

          await getConsumptionDataList({
            consumptionSite: res.id,
            consumptionStartAfter: root.$store.state.period[0],
            consumptionStartBefore: root.$store.state.period[1],
            limit: 24,
            offset: 0,
          })
            .then(res => {
              state.consumptionData = res;
              state.consumptionDataLoaded = true;

              if (state.consumptionData.results) {
                state.consumptionData.results.sort(
                  dynamicSort("consumption_start")
                );
              }
            })
            .catch(() => {
              state.consumptionDataLoaded = false;
              // alert(JSON.stringify(err.body));
              console.error("ConsumptionSite.vue getConsumptionSiteData");
            });
        })
        .catch(err => {
          state.detailsLoaded = false;
          if (err.statusCode === 404) root.$router.push({ name: "NotFound" });
        });
    };

    consumptionSiteDataFn();

    const closeEditModal = () => {
      state.editModalVisible = false;
      state.details = cloneDeep(state.detailsInit);
    };

    const deleteSite = () => {
      root.$confirm(
        /* Message */
        `
          Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${state.details.name}</code>?
          <br>
          This action can only be <span class="font-medium">reversed by a superadmin</span>.
        `,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: root.$i18n.t("labels.delete") as string,
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteConsumptionSiteData(root.$route.params.id)
                .then(() => {
                  instance.confirmButtonLoading = false;

                  Notification({
                    title: "Operation successful",
                    message: `${state.details.name} deleted successfully`,
                    type: "success",
                    position: "bottom-left",
                  });

                  typeof state.details.consumer !== "string" &&
                    root.$router.push({
                      name: "Consumer",
                      params: { id: state.details.consumer.id },
                    });

                  done();
                })
                // .catch(err => alert(JSON.stringify(err)));
                .catch(() => console.error("ConsumptionSite.vue beforeClose"));
              /*  */
            } else {
              done();
            }
          },
        }
      );
    };

    const siteUpdated = () => {
      consumptionSiteDataFn();
      state.editModalVisible = false;
      state.uploadModalVisible = false;
    };

    const productionImported = () => {
      state.isRouterAlive = false;
      state.uploadModalVisible = false;
      root.$nextTick(() => {
        state.isRouterAlive = true;
      });
    };

    watch(
      () => root.$store.state.period,
      (newVal, oldVal) => {
        root.$router
          .replace({
            query: Object.assign(
              {},
              {
                start: root.$store.state.period[0].split("T")[0],
                end: root.$store.state.period[1].split("T")[0],
              }
            ),
          })
          .catch(() => {});

        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          consumptionSiteDataFn();
        }
      },
      { deep: true, immediate: true }
    );

    /**
     * ! Getters
     */
    const totalAmountData = computed(() => {
      return {
        total_amount_matched:
          (state.consumptionData.total_amount_matched_solar !== null &&
          state.consumptionData.total_amount_matched_solar !== undefined
            ? state.consumptionData.total_amount_matched_solar
            : 0) +
          (state.consumptionData.total_amount_matched_wind !== null &&
          state.consumptionData.total_amount_matched_wind !== undefined
            ? state.consumptionData.total_amount_matched_wind
            : 0) +
          (state.consumptionData.total_amount_matched_hydro !== null &&
          state.consumptionData.total_amount_matched_hydro !== undefined
            ? state.consumptionData.total_amount_matched_hydro
            : 0) +
          (state.consumptionData.total_amount_matched_marine !== null &&
          state.consumptionData.total_amount_matched_marine !== undefined
            ? state.consumptionData.total_amount_matched_marine
            : 0) +
          (state.consumptionData.total_amount_matched_thermal !== null &&
          state.consumptionData.total_amount_matched_thermal !== undefined
            ? state.consumptionData.total_amount_matched_thermal
            : 0) +
          (state.consumptionData.total_amount_matched_solid !== null &&
          state.consumptionData.total_amount_matched_solid !== undefined
            ? state.consumptionData.total_amount_matched_solid
            : 0) +
          (state.consumptionData.total_amount_matched_liquid !== null &&
          state.consumptionData.total_amount_matched_liquid !== undefined
            ? state.consumptionData.total_amount_matched_liquid
            : 0) +
          (state.consumptionData.total_amount_matched_gaseous !== null &&
          state.consumptionData.total_amount_matched_gaseous !== undefined
            ? state.consumptionData.total_amount_matched_gaseous
            : 0),
        total_amount_consumed: state.consumptionData.total_amount_consumed,
        total_amount_matched_solar:
          state.consumptionData.total_amount_matched_solar,
        total_amount_matched_wind:
          state.consumptionData.total_amount_matched_wind,
        total_amount_matched_hydro:
          state.consumptionData.total_amount_matched_hydro,
        total_amount_matched_marine:
          state.consumptionData.total_amount_matched_marine,
        total_amount_matched_thermal:
          state.consumptionData.total_amount_matched_thermal,
        total_amount_matched_solid:
          state.consumptionData.total_amount_matched_solid,
        total_amount_matched_liquid:
          state.consumptionData.total_amount_matched_liquid,
        total_amount_matched_gaseous:
          state.consumptionData.total_amount_matched_gaseous,
        total_production_sites_matched:
          state.consumptionData.total_production_sites_matched,
      };
    });

    const hourlyAggregatedData = computed(() =>
      state.consumptionData.hourly_aggregated_data?.sort(dynamicSort("hour"))
    );
    const allHourlyData = computed(() =>
      state.consumptionData.hourly_aggregated_data_for_each_day?.sort(
        dynamicSort("date")
      )
    );

    provide(
      "consumptionData",
      computed(() => state.consumptionData)
    );

    const changeF = (newVal: any) => {
      state.consumptionSiteFormValue = newVal;
    };

    const closeDialog = () => {
      if (state.consumptionSiteFormValue) {
        root.$confirm(
          /* Message */
          root.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          root.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: root.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                state.isRouterAlive = false;
                root.$nextTick(() => {
                  state.isRouterAlive = true;
                });
                state.uploadModalVisible = false;
                state.editModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          }
        );
      } else {
        state.isRouterAlive = false;
        root.$nextTick(() => {
          state.isRouterAlive = true;
        });
        state.editModalVisible = false;
        state.uploadModalVisible = false;
        state.consumptionSiteFormValue = false;
      }
    };
    const chartChange = (e: any) => {
      state.chartName = e;
    };

    return {
      ...toRefs(state),
      roles,
      deleteSite,
      closeEditModal,
      siteUpdated,
      formattedAddress,
      totalAmountData,
      hourlyAggregatedData,
      seperateThousand,
      OrganizationTypes,
      closeDialog,
      changeF,
      productionImported,
      chartChange,
      allHourlyData,
    };
  },
});
